<template>
  <!-- 答案解析界面 -->
  <div class="reviewedInfo">
    <van-nav-bar
      title="安全生产培训（1/2）"
      left-text=""
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="banner">
      <span>96<span>分</span></span>
      
    </div>
    <div class="content" v-for="(bigType, qtIdx) in qsList" :key="qtIdx">
      <!-- 题型信息 -->
      <div class="qsType">
        <span>{{ bigOrder[qtIdx] }}</span>
        <span class="typeName">{{ bigType.qsType }}</span>
        <span
          class="grade"
          v-if="
            bigType.qsType == '单选题' ||
              bigType.qsType == '多选题' ||
              bigType.qsType == '判断题'
          "
        >
          {{
            `(共${bigType.qsDetails.length}题，每题${
              bigType.mark
            }分，共${bigType.qsDetails.length * bigType.mark}分)`
          }}
        </span>
        <span class="grade" v-if="bigType.qsType == '填空题'">
          {{
            `(共${bigType.qsDetails.length}题，每空${
              bigType.mark
            }分，共${bigType.mark * tkNum}分)`
          }}
        </span>
        <span class="grade" v-if="bigType.qsType == '简答题'">
          {{ `(共${bigType.qsDetails.length}题，共${brief}分)` }}
        </span>
      </div>
      <!-- 题目内容详情 -->
      <div
        class="qsContent"
        v-for="(item, index) in bigType.qsDetails"
        :key="index"
      >
        <div class="qsTit">
          {{ `${index + 1}.${item.qsTit}` }}
          <span
            v-if="bigType.qsType == '填空题'"
            style="margin-left:15px;color:#2B8DF0;"
            >{{
              `(${bigType.mark * (item.qsTit.split("____").length - 1)}分)`
            }}</span
          >
          <span
            v-if="bigType.qsType == '简答题'"
            style="margin-left:15px;color:#2B8DF0;"
            >{{ `(${item.mark}分)` }}</span
          >
        </div>
        <!-- 单选题 -->
        <van-radio-group
          class="singleChoice"
          v-model="item.answer"
          @change="radioAnswer"
          v-if="bigType.qsType == '单选题'"
        >
          <van-radio
            :name="abList[opIdx]"
            v-for="(opt, opIdx) in item.options"
            :key="opIdx"
            ><span class="iico">{{ abList[opIdx] }}</span
            >{{ opt.opTit }}</van-radio
          >
        </van-radio-group>
        <!-- 多选题 -->
        <van-checkbox-group
          v-model="item.answer"
          v-else-if="bigType.qsType == '多选题'"
          @change="checkboxAnswer"
        >
          <van-checkbox
            :name="abList[opIdx]"
            v-for="(opt, opIdx) in item.options"
            :key="opIdx"
            ><span class="iico">{{ abList[opIdx] }}</span
            >{{ opt.opTit }}</van-checkbox
          >
        </van-checkbox-group>
        <!-- 判断题 -->
        <van-radio-group
          class="judge"
          v-model="item.answer"
          v-if="bigType.qsType == '判断题'"
          @change="judgeAnswer"
        >
          <van-radio name="1">正确</van-radio>
          <van-radio name="0">错误</van-radio>
        </van-radio-group>
        <!-- 填空题 -->
        <div
          class="score"
          v-if="item.answer.length != 0 && bigType.qsType == '填空题'"
          :style="{ color: item.score == '正确' ? '#2B8DF0' : '#F53838' }"
        >
          <span
            ><span style="color:#2B8DF0"
              >答对{{ tkRorW[index].rightNum }}空</span
            ><span style="color:#333">/</span><span style="color:#F53838"
              >答错{{ tkRorW[index].wrongNum }}空</span
            ></span
          >
          <span
            :style="{
              color: tkRorW[index].rightNum > 0 ? '#2B8DF0' : '#F53838',
            }"
            >+{{ tkRorW[index].rightNum * bigType.mark }}分</span
          >
        </div>
        <van-cell-group v-if="bigType.qsType == '填空题'" class="tk">
          <div v-for="(tk, tkIdx) in item.answer" :key="tkIdx">
            <van-field
              v-model="tk.daAn"
              :label="'填空' + parseInt(tkIdx + 1)"
              placeholder="请输入对应答案"
            >
              <template #left-icon>
                <span v-if="tk.score">
                  <svg-icon icon-class="checkOutAnswer-1"></svg-icon>
                </span>
                <span v-else>
                  <svg-icon icon-class="checkOutAnswer-2"></svg-icon>
                </span>
              </template>
            </van-field>
            <van-field
              class="zqdn"
              v-model="tk.zqDaAn"
              label="正确答案"
              placeholder="请输入对应答案"
            />
          </div>
        </van-cell-group>
        <!-- 简答题 -->
        <van-field
          class="brief"
          v-if="bigType.qsType == '简答题'"
          v-model="item.answer"
          rows="10"
          autosize
          label=""
          type="textarea"
          maxlength="500"
          placeholder="请输入答案"
          show-word-limit
        />
        <!-- 上传题 -->
        <van-uploader
          v-if="bigType.qsType == '附件题'"
          v-model="item.answer"
          multiple
          :max-count="1"
        >
          <van-button icon="plus" type="info">上传文件</van-button>
        </van-uploader>
        <!-- 评分 -->
        <div
          class="score"
          v-if="item.score != '' && bigType.qsType != '填空题'"
          :style="{ color: item.score == '正确' ? '#2B8DF0' : '#F53838' }"
        >
          <span v-if="item.score == '正确'">
            <svg-icon icon-class="checkOutAnswer-1"></svg-icon>
            回答正确
          </span>
          <span v-else-if="item.score == '错误'">
            <svg-icon icon-class="checkOutAnswer-2"></svg-icon>
            回答错误
          </span>
          <span>+5分</span>
        </div>
        <!-- 简答和附件需要人工评分 -->
        <div
          class="score"
          v-if="item.score == ''"
          style="margin-top:15px;color:#2B8DF0;"
        >
          <span>
            需要人工判分
          </span>
          <span>待判分</span>
        </div>
        <!-- 解析 -->
        <div
          class="analysis"
          v-if="item.score != '' && bigType.qsType != '填空题'"
        >
          <div class="zqda" v-if="item.score == '错误'">
            <span style="color:#2B8DF0;">正确答案：</span>
            <p>
              A、由人到物 | B、有小到大 | C、有物及人 | D、有上到下
            </p>
          </div>
          <div class="jiexi">
            <span>答案解析：</span>
            <p>
              秘书（Secretary）可以指： 1.政党之内的要职，最高者称为总书记。
              2.写字楼及商业机构内的文员职位，专长是速
              记、打字、安排日程、会议、订机票、订酒店
              3.香港决策局的最高级公务员职位。
              美国联邦政府的部长英文名称亦为（总统的）
              部会秘书，如国务卿为Secretary of State、陆 军部长为Secretary of
              Army，平时称为部长先 生Mr.Secretary。解析为“为上级处理日常事
              务和杂务的人”所以“由上到下”
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Notify } from "vant";
export default {
  data() {
    return {
      bigOrder: ["一、", "二、", "三、", "四、", "五、", "六、", "七、"],
      abList: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"],
      isfinish: false,
      currentQs: [],
      bgIdx: 0,
      smIdx: 0,
      qsList: [
        {
          qsType: "单选题",
          mark: "5",
          qsDetails: [
            {
              qsTit: "秘书的涵义，有一个( )的演变过程。",
              answer: "A",
              score: "正确", //回答是否正确
              options: [
                { opTit: "由人到物" },
                { opTit: "由小到大" },
                { opTit: "由物及人" },
                { opTit: "由上到下" },
              ],
            },
            {
              qsTit: "你最喜欢的球星?",
              answer: "A",
              score: "错误", //回答是否正确
              options: [
                { opTit: "科比" },
                { opTit: "乔丹" },
                { opTit: "欧文" },
                { opTit: "罗斯" },
              ],
            },
          ],
        },
        {
          qsType: "多选题",
          mark: "5",
          qsDetails: [
            {
              qsTit: "你喜欢吃什么水果？",
              answer: ["A", "B"],
              score: "正确",
              options: [
                { opTit: "香蕉" },
                { opTit: "苹果" },
                { opTit: "猕猴桃" },
                { opTit: "黑布李" },
              ],
            },
            {
              qsTit: "你喜欢什么颜色?",
              answer: ["C", "D"],
              score: "正确",
              options: [
                { opTit: "黄色" },
                { opTit: "绿色" },
                { opTit: "蓝色" },
                { opTit: "紫色" },
              ],
            },
          ],
        },
        {
          qsType: "判断题",
          mark: "5",
          qsDetails: [
            {
              qsTit: "太阳围绕地球转",
              answer: "0",
              score: "正确",
            },
            {
              qsTit: "地球是平的",
              answer: "1",
              score: "正确",
            },
          ],
        },
        {
          qsType: "填空题",
          mark: "5",
          qsDetails: [
            {
              qsTit: "床前____光，疑是____霜",
              answer: [
                { daAn: "666", zqDaAn: "666", score: true },
                { daAn: "888", zqDaAn: "777", score: false },
              ],
            },
            {
              qsTit: "举头____明月，低头____故乡举头____明月",
              answer: [
                { daAn: "666", zqDaAn: "666", score: true },
                { daAn: "888", zqDaAn: "777", score: false },
                { daAn: "999", zqDaAn: "999", score: true },
              ],
            },
          ],
        },
        {
          qsType: "简答题",
          qsDetails: [
            {
              qsTit: "为什么地球会围绕太阳转？",
              answer: "",
              mark: 5,
              score: "",
            },
            {
              qsTit: "中国的天问一号成功首次踏上火星有什么历史意义？",
              answer: "",
              mark: 5,
              score: "",
            },
          ],
        },
        {
          qsType: "附件题",
          qsDetails: [
            {
              qsTit: "请上传冒泡排序的代码？",
              answer: [],
              mark: 5,
              score: "",
            },
            {
              qsTit: "请上传一个轮播图的代码？",
              answer: [],
              mark: 5,
              score: "",
            },
          ],
        },
      ],
    };
  },
  computed: {
    // 计算总共有多少道题
    qsTotalNum() {
      let num = 0;
      if (this.qsList.length > 0) {
        this.qsList.forEach((item) => {
          num += item.qsDetails.length;
        });
      }
      return num;
    },
    // 计算有多少题型，每题型有多少道题
    qsNum() {
      let ary = [];
      if (this.qsList.length > 0) {
        this.qsList.forEach((item) => {
          let len = item.qsDetails.length;
          ary.push(len);
        });
      }
      return ary;
    },
    // 计算填空题有多少个空
    tkNum() {
      let num = 0;
      if (this.qsList.length > 0) {
        let ary = this.qsList.filter((qs) => {
          return qs.qsType == "填空题";
        });
        if (ary[0].qsDetails.length > 0) {
          // 说明有填空题
          ary[0].qsDetails.forEach((tk) => {
            num += tk.qsTit.split("____").length - 1;
          });
        }
      }
      return num;
    },
    // 计算简答题的总分
    brief() {
      let num = 0;
      if (this.qsList.length > 0) {
        let ary = this.qsList.filter((qs) => {
          return qs.qsType == "简答题";
        });
        if (ary[0].qsDetails.length > 0) {
          // 说明有简答题
          ary[0].qsDetails.forEach((wd) => {
            num += wd.mark;
          });
        }
      }
      return num;
    },
    // 计算试卷有多少题没有答案
    noAnswer() {
      let num = 0;
      if (this.qsList.length > 0) {
        this.qsList.forEach((qs) => {
          if (qs.qsType == "单选题") {
            let ary = qs.qsDetails.filter((t) => {
              return t.answer == "";
            });
            num += ary.length;
          } else if (qs.qsType == "多选题") {
            let ary = qs.qsDetails.filter((t) => {
              return t.answer.length == 0;
            });
            num += ary.length;
          } else if (qs.qsType == "判断题") {
            let ary = qs.qsDetails.filter((t) => {
              return t.answer == "-1";
            });
            num += ary.length;
          } else if (qs.qsType == "填空题") {
            let ary = qs.qsDetails.filter((t) => {
              return t.answer.length == 0;
            });
            num += ary.length;
          } else if (qs.qsType == "简答题") {
            let ary = qs.qsDetails.filter((t) => {
              return t.answer == "";
            });
            num += ary.length;
          } else if (qs.qsType == "附件题") {
            let ary = qs.qsDetails.filter((t) => {
              return t.answer.length == 0;
            });
            num += ary.length;
          }
        });
      }
      return num;
    },
    // 计算填空题有多少道对的和多少到错误的题
    tkRorW() {
      let arys = [];
      if (this.qsList.length > 0) {
        let ary = this.qsList.filter((qs) => {
          return qs.qsType == "填空题";
        });
        if (ary[0].qsDetails.length > 0) {
          // 说明有填空题
          ary[0].qsDetails.forEach((tk) => {
            let o = { rightNum: 0, wrongNum: 0 };
            tk.answer.forEach((an) => {
              if (an.score) {
                o.rightNum += 1;
              } else {
                o.wrongNum += 1;
              }
            });
            arys.push(o);
          });
        }
      }
      return arys;
    },
  },
  methods: {
    // 判断题
    judgeAnswer(val) {
      this.qsList[this.bgIdx].qsDetails[this.smIdx].answer = val;
    },
    // 多选题选择答案
    checkboxAnswer(val) {
      this.qsList[this.bgIdx].qsDetails[this.smIdx].answer = val;
    },
    // 单选题选择答案
    radioAnswer(val) {
      this.qsList[this.bgIdx].qsDetails[this.smIdx].answer = val;
    },
    // 点击返回
    onClickLeft() {
    
        this.$router.push("/examRecord");
      
    },
  },
  created() {
    window.T = this;
  },
};
</script>

<style lang="less" scoped>
.reviewedInfo {
  background: #fff;
  height: 100vh;

  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .banner {
    height: 124px;
    // background: url("~@/assets/img/examination1.png") no-repeat;
    // background-size: cover;
    // background-position-y: -150px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2B8DF0;
    color:#fff;
    span{
      font-size: 90px;
      span{
        font-size:60px;
      }
    }
    
  }
  .content {
    font-size: 36px;
    font-family: Source Han Sans SC;
    padding: 30px 0;
    border-bottom: 1px solid #ccc;
    background: #fff;
    .qsType {
      margin-bottom: 28px;
      border-bottom: 1px solid #ccc;
      padding: 0 30px;
      padding-bottom: 28px;
      .typeName {
        margin-right: 15px;
      }
      .grade {
        color: #2b8df0;
      }
    }
    .qsContent {
      padding: 36px 0;
      padding: 0 30px;
      color: #333;
      font-size: 32px;
      margin-bottom: 30px;
      .qsTit {
        margin-bottom: 42px;
      }
      // 单选样式
      .singleChoice {
        padding-left: 1em;
        .van-radio {
          margin-bottom: 40px;
        }
        .van-radio {
          /deep/ .van-radio__icon--round {
            display: none;
          }
          /deep/.van-radio__label {
            margin-left: 0px;
          }
          /deep/ .iico {
            width: 60px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            background: #ebebeb;
            border-radius: 50%;
            display: inline-block;
            margin-right: 16px;
          }
        }
        .van-radio[aria-checked="true"] {
          /deep/ .iico {
            display: none;
          }
          /deep/ .van-radio__icon--round {
            display: inline-block;
            width: 60px;
            height: 60px;
            margin-right: 16px;
            .van-icon {
              width: 100%;
              height: 100%;
              line-height: 60px;
              text-align: center;
            }
          }
        }
      }
      // 多选样式
      .van-checkbox-group {
        padding-left: 1em;
        .van-checkbox {
          margin-bottom: 40px;
        }
        .van-checkbox {
          /deep/ .van-checkbox__icon--round {
            display: none;
          }
          /deep/.van-checkbox__label {
            margin-left: 0px;
          }
          /deep/ .iico {
            width: 60px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            background: #ebebeb;
            border-radius: 50%;
            display: inline-block;
            margin-right: 16px;
          }
        }
        .van-checkbox[aria-checked="true"] {
          /deep/ .iico {
            display: none;
          }
          /deep/ .van-checkbox__icon--round {
            display: inline-block;
            width: 60px;
            height: 60px;
            margin-right: 16px;
            .van-icon {
              width: 100%;
              height: 100%;
              line-height: 60px;
              text-align: center;
            }
          }
        }
      }
      // 判断题样式
      .judge {
        padding-left: 1em;
        .van-radio {
          margin-bottom: 40px;
          /deep/ .van-radio__icon--round {
            display: inline-block;
            width: 60px;
            height: 60px;
            margin-right: 16px;
            .van-icon {
              width: 100%;
              height: 100%;
              line-height: 60px;
              text-align: center;
            }
          }
        }
      }
      // 填空题样式
      .tk {
        div {
          .van-cell {
            /deep/.van-cell__title {
              width: 4em;
              margin-right: 0;
            }
          }
          .zqdn {
            /deep/.van-cell__title {
              width: 5.3em;
              margin-right: 0;
            }
          }
        }
      }
      // 简答题样式
      .brief {
        display: flex;
        flex-direction: column;
        border: 1px solid #ccc;
      }
      // 上传题样式
      /deep/.van-uploader {
        width: 100%;
        /deep/.van-uploader__wrapper {
          display: flex;
          justify-content: flex-end;
          flex-flow: row-reverse;
          // flex-direction: column-reverse;
          /deep/.van-uploader__input-wrapper {
            // background: red;
            width: 50%;
            // width:80%;
          }
        }
      }
      // 评分样式
      .score {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 64px;
        background: #e6e6e6;
        border-radius: 42px;
        padding: 0 15px;
        font-size: 32px;
        margin-bottom: 16px;
        span:first-child {
          display: flex;
          align-items: center;
        }
        .svg-icon {
          width: 40px;
          height: 40px;
          margin-right: 10px;
        }
      }
      // 答案解析样式
      .analysis {
        background: #e6e6e6;
        border-radius: 42px;
        padding: 30px;
        font-size: 32px;
        font-family: Source Han Sans SC;
        color: #333;
      }
    }
  }
}
</style>
